import FlexiProgressBar from "@bit/flexibill.flexi.flexi-progress-bar";
import { useIntl } from "react-intl";
import React, { useState } from "react";
import { makeCurrencyValueFormatter } from "../../utils/makeCurrencyValueFormatter";
import { defineMessages } from "react-intl.macro";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LimitChangeDialog from "../../components/LimitChangeDialog";
import Tooltip from "@material-ui/core/Tooltip";
import { Box, Chip } from "@material-ui/core";
import FlexiExpandableRemoteList from "@bit/flexibill.flexi.flexi-expandable-remote-list";
import withStyles from "@material-ui/core/styles/withStyles";
import useHasPermission from "../../components/RoleProvider/useHasPermission";
import { ROLE_FLEXIPAY_INVOICE_MANAGER } from "../../roles";
import {
  useEmailSentNotification,
  useErrorOccurredNotification
} from "../../hooks/useNotificationState";
import { useAgreements, useMutator } from "../../hooks/useApi";
import { getFlexipayUrl, getUrlWithCompanyId } from "../../utils/urlHelpers";
import { selectErrorNotificationMessage } from "../../utils/selectErrorMessage";
import faktorSvg from "../../images/onboarding_faktszerz.svg";
import useRedirect from "../../hooks/useRedirect";
import { useHistory } from "react-router-dom";
import purpleHourGlass from "../../images/purpleHourGlass.svg";
import smallGreenTick from "../../images/redesignImages/acceptSvg.svg";
import redHourGlass from "../../images/redHourGlass.svg";
import rejected from "../../images/redesignImages/Rejected.svg";

const messages = defineMessages({
  pageTitle: {
    id: "pageTitle.factorContractPage",
    defaultMessage: "Szerződések"
  },
  noContractsTitle: {
    id: "noContractsTitle.factorContractPage",
    defaultMessage: "Jelenleg nem rendelkezik faktorszerződéssel"
  },
  noContractsText: {
    id: "noContractsTitle.factorContractPage",
    defaultMessage:
      "Itt találhatja meg vevőnként a felhasználható keretet, illetve kérheti a limitek módosítását."
  }
});

// const useStyles = makeStyles({
//   suspended: {
//     height: "19px",
//     marginRight: "5px",
//     borderRadius: "5px",
//     backgroundColor: "#ebdbf8",
//     color: "#9013fe"
//   },
//   plus: {
//     width: "20px !important",
//     height: "20px !important",
//     backgroundColor: "#e4f1d6",
//     border: "1px solid",
//     borderColor: "#a6d170",
//     marginLeft: "15px"
//   },
//   minus: {
//     width: "20px !important",
//     height: "20px !important",
//     backgroundColor: "#f6e5e5",
//     border: "1px solid",
//     borderColor: "#ee605d",
//     marginLeft: "15px"
//   }
// });

const SuspendedChip = withStyles({
  root: {
    height: "19px !important",
    backgroundColor: "#ebdbf8",
    borderRadius: "5px",
    color: "#9013fe",
    marginRight: "5px"
  }
})(Chip);

const PlusButton = withStyles({
  root: {
    width: "20px !important",
    height: "20px !important",
    backgroundColor: "#e4f1d6",
    border: "1px solid",
    borderColor: "#a6d170",
    marginLeft: "15px"
  }
})(IconButton);

const MinusButton = withStyles({
  root: {
    width: "20px !important",
    height: "20px !important",
    backgroundColor: "#f6e5e5",
    border: "1px solid",
    borderColor: "#ee605d",
    marginLeft: "15px"
  }
})(IconButton);

const columnMessages = defineMessages({
  agreementName: {
    id: "listColumnHeader.factoryContracts.agreementName",
    defaultMessage: "Szerződés száma"
  },
  partnerName: {
    id: "listColumnHeader.factoryContracts.partnerName",
    defaultMessage: "Vevő neve"
  },
  status: {
    id: "listColumnHeader.factoryContracts.status",
    defaultMessage: "Státusz"
  },
  limitAmount: {
    id: "listColumnHeader.factoryContracts.limitAmount",
    defaultMessage: "Limit"
  },
  limitUsed: {
    id: "listColumnHeader.factoryContracts.limitUsed",
    defaultMessage: "Limit kihasználtság"
  },
  freeLimit: {
    id: "listColumnHeader.factoryContracts.freeLimit",
    defaultMessage: "Felhasználható keret"
  }
});

const makeUsageRenderer = intl => params => {
  const currencyFormatter = makeCurrencyValueFormatter(intl);
  const { push: redirect } = useHistory();
  return (
    <Box display={"flex"} mt={"8px"} width={"100%"}>
      <IconButton onClick={() => redirect("/app/limit-change-list")}>
        -
      </IconButton>
      <FlexiProgressBar
        tooltip={`${currencyFormatter(params)} a kihasználtság`}
        percentage={(params.data.limitUsed / params.data.limitAmount) * 100}
      />
      <IconButton onClick={() => redirect("/app/limit-change-list")}>
        +
      </IconButton>
    </Box>
  );
};

const ContractList = ({ localItems, hideSearchBar }) => {
  const hasPermission = useHasPermission();
  const isInvoiceManager = hasPermission([ROLE_FLEXIPAY_INVOICE_MANAGER]);
  const intl = useIntl();
  const [limitDialogData, setLimitDialogData] = useState(null);
  const {
    data: agreements,
    revalidate,
    isValidating: loading
  } = useAgreements();
  const [post] = useMutator("POST");
  const showNoData = !loading && !Boolean(agreements?.length);
  const showList = !loading && Boolean(agreements?.length);
  const makeStatusRenderer = () => ({ value, data }) => {
    const getStatus = () => {
      switch (value) {
        case "active":
          return <Typography>Aktív</Typography>;
        case "inactive":
          return <Typography>Inaktív</Typography>;
        case "ceased":
          return <Typography>Megszűnt</Typography>;
        case "forbidden":
          return <Typography>Tiltott</Typography>;
      }
    };

    return <span style={{ display: "inline-flex" }}>{getStatus()}</span>;
  };

  const getColumns = (intl, minusButtonClick, plusButtonClick) => {
    const currencyFormatter = makeCurrencyValueFormatter(intl);

    return {
      contractName: {
        headerName: intl.formatMessage(columnMessages.agreementName),
        field: "agreementName",
        sortable: true,
        autoHeight: true,
        resizable: true,
        mobileCellXs: 12,
        cellClass: "cell-wrap-text",
        cellStyle: ({ data }) => {
          if (data.suspended === true) {
            return { opacity: "0.4" };
          }
        },
        cellRendererFramework: ({ data, value }) => {
          return (
            <>
              <Tooltip title={data.suspendedMessage || ""}>
                <span>
                  {data.suspended && <SuspendedChip label={"felfüggesztve"} />}
                </span>
              </Tooltip>

              <Typography>{value}</Typography>
            </>
          );
        }
      },
      partnerName: {
        headerName: intl.formatMessage(columnMessages.partnerName),
        field: "customerName",
        sortable: true,
        autoHeight: true,
        resizable: true,
        mobileCellXs: 12,
        cellStyle: ({ data }) => {
          if (data.suspended === true) {
            return { opacity: "0.4", pointerEvents: "none" };
          }
        }
      },
      status: {
        headerName: intl.formatMessage(columnMessages.status),
        field: "status",
        sortable: true,
        autoHeight: true,
        resizable: true,
        mobileCellXs: 12,
        cellRendererFramework: makeStatusRenderer()
      },
      limitAmount: {
        headerName: intl.formatMessage(columnMessages.limitAmount),
        field: "limitAmount",
        valueFormatter: currencyFormatter,
        sortable: true,
        autoHeight: true,
        cellClass: "cell-wrap-text",
        mobileCellXs: 12,
        cellStyle: ({ data }) => {
          if (data.suspended === true) {
            return { opacity: "0.4", pointerEvents: "none" };
          }
        },
        cellRendererFramework: ({ data, value }) => {
          return (
            <Tooltip
              placement={"top"}
              title={
                data.hasLimitAgreementClaim ? "Limit módosítás folyamatban" : ""
              }
            >
              <Grid container>
                {!isInvoiceManager && (
                  <Grid item>
                    {/*<MinusButton*/}
                    {/*  disabled={data.hasLimitAgreementClaim}*/}
                    {/*  onClick={() => minusButtonClick(data)}*/}
                    {/*>*/}
                    {/*  <Typography style={{ color: "#EE605D" }}>-</Typography>*/}
                    {/*</MinusButton>*/}
                  </Grid>
                )}
                <Grid
                  item
                  style={{
                    width: "100px",
                    alignSelf: "center"
                  }}
                >
                  <Typography>{currencyFormatter({ value, data })}</Typography>
                </Grid>
                {!isInvoiceManager && (
                  <Grid item>
                    {/*<PlusButton*/}
                    {/*  disabled={data.hasLimitAgreementClaim}*/}
                    {/*  onClick={() => plusButtonClick(data)}*/}
                    {/*>*/}
                    {/*  <Typography style={{ color: "#a6d170" }}>+</Typography>*/}
                    {/*</PlusButton>*/}
                  </Grid>
                )}
              </Grid>
            </Tooltip>
          );
        }
      },
      freeLimit: {
        headerName: intl.formatMessage(columnMessages.freeLimit),
        valueGetter: ({ data }) => data.limitAmount - data.limitUsed || null,
        valueFormatter: currencyFormatter,
        sortable: true,
        autoHeight: true,
        cellClass: "cell-wrap-text",
        mobileCellXs: 12,
        cellStyle: ({ data }) => {
          if (data.suspended === true) {
            return { opacity: "0.4", pointerEvents: "none" };
          }
        }
      },
      limitUsed: {
        headerName: intl.formatMessage(columnMessages.limitUsed),
        field: "limitUsed",
        cellRendererFramework: makeUsageRenderer(intl),
        sortable: true,
        autoHeight: true,
        mobileCellXs: 12,
        cellStyle: ({ data }) => {
          if (data.suspended === true) {
            return { opacity: "0.4", pointerEvents: "none" };
          }
        }
      }
    };
  };

  const handleDialog = data => {
    setLimitDialogData(data);
  };

  const columns = getColumns(intl, handleDialog, handleDialog);

  return (
    <>
      {showList && (
        <>
          <FlexiExpandableRemoteList
            columnDefs={Object.keys(columns).map(key => columns[key])}
            localItems={localItems || agreements}
            suppressExpand
            hideSearchBar={hideSearchBar}
            hidePager={hideSearchBar}
          />
          <LimitChangeDialog
            onClose={() => setLimitDialogData(null)}
            open={Boolean(limitDialogData)}
            company={limitDialogData}
            onSubmit={async values => {
              const { data, error } = await post(
                getUrlWithCompanyId(
                  `/agreement/${limitDialogData.id}/limit-change-claim`
                ),
                {
                  agreement: limitDialogData.id,
                  modifiedLimit: values.newLimit
                }
              );

              if (data) {
                await revalidate();
              }

              setLimitDialogData(null);
            }}
          />
        </>
      )}
      {showNoData && (
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item style={{ paddingTop: "36px", paddingBottom: "36px" }}>
            <img alt={"faktorSvg"} src={faktorSvg} />
          </Grid>
          <Grid item style={{ paddingBottom: "12px" }}>
            <Typography variant={"h3"}>
              {intl.formatMessage(messages.noContractsTitle)}
            </Typography>
          </Grid>
          <Grid item style={{ textAlign: "center" }}>
            <Typography>
              {intl.formatMessage(messages.noContractsText)}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ContractList;
