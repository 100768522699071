import React, { useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CalculatorForm from "./CalculatorForm";
import { useProfileData, useSzamlazzhuCustomers } from "../../hooks/useApi";
import withStyles from "@material-ui/core/styles/withStyles";

const StyledPaper = withStyles(theme => {
  return {
    root: {
      position: "relative",
      marginTop: "30px",
      "&:before": {
        position: "absolute",
        backgroundColor: props => `url(${props.calcImg})`,
        left: "calc(50% - 30px)",
        top: "-30px",
        width: "60px",
        height: "60px",
        content: ""
      }
    }
  };
})(Paper);

const useCalculatorInitialValues = szamlazzHu => {
  const [initialValues, setInitialValues] = useState([]);
  if (szamlazzHu) {
    Promise.resolve([
      {
        calculation: {
          natureOfTransaction: "CASE_BY_CASE",
          needInsurance: "true",
          currency: "HUF"
        },
        localBusiness: "true",
        party: {
          partyName: "FOO Kft.",
          companyLegalForm: "006",
          taxNumber: "24768191-2-02",
          email: "",
          telephone: "",
          registrationAddress: {
            country: "HU",
            postalZone: "7677",
            cityName: "Orfű",
            addressLine: "Széchenyi tér 1."
          },
          postalAddress: {
            country: "HU",
            postalZone: "7677",
            cityName: "Orfű",
            addressLine: "Széchenyi tér 1."
          }
        }
      }
    ]).then(setInitialValues);
  }

  return initialValues;
};

const Calculator = ({ onSubmit, initialValues }) => {
  const { szamlazzHu } = useProfileData();
  const { data: szamlazzhuCustomers = [] } = useSzamlazzhuCustomers(szamlazzHu);
  const initialOrEmptyValues = useMemo(
    () =>
      initialValues || {
        companies: [
          {
            calculation: {
              natureOfTransaction: "CASE_BY_CASE",
              needInsurance: "true",
              currency: "HUF"
            },
            localBusiness: "true"
          }
        ]
      },
    [szamlazzHu, szamlazzhuCustomers]
  );
  return (
    <Grid container spacing={2}>
      <Grid xs={12} item>
        <CalculatorForm
          onSubmit={onSubmit}
          initialValues={initialOrEmptyValues}
        />
      </Grid>
    </Grid>
  );
};

export default Calculator;
