import React, { useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { number } from "../../utils/validators";
import FlexiForm, {
  FieldArray,
  Fields,
  Field
} from "@bit/flexibill.flexi.flexi-form";
import { getFlexipayUrl } from "../../utils/urlHelpers";
import { Divider, IconButton, SvgIcon, Tooltip } from "@material-ui/core";
import { getConfig } from "../../utils/config";
import Box from "@material-ui/core/Box/Box";
import { useProfileData, useSzamlazzhuCustomers } from "../../hooks/useApi";
import infoIcon from "../../images/redesignImages/Ikon (2).svg";
import FlexiDialog from "@bit/flexibill.flexi.flexi-dialog";
import { defineMessages } from "react-intl.macro";
import { FormattedHTMLMessage } from "react-intl";
import Paper from "@material-ui/core/Paper";

const Line = ({
  name,
  width,
  szamlazzHu,
  whitelistCustomers,
  onCompanyChange
}) => {
  window.whitelistCustomers = whitelistCustomers;
  const invoicesAndTerms = useMemo(
    () => [
      [
        {
          fieldType: "currency",
          name: `${name}.calculation.monthlyInvoicesTotal`,
          required: true,
          label: "Kiállított számlák összege",
          tooltipText:
            "Add meg a számlák összegét, melyet várhatóan faktorálnál a vevődre. Alapul veheted a korábban kiállított számlákat, de egy tetszőleges, a jövőre vonatkozó összeget is megadhatsz."
        },
        {
          fieldType: "text",
          name: `${name}.calculation.invoiceTerms`,
          required: true,
          label: "Számlák futamideje (nap)",
          validate: number,
          InputProps: { type: "number" },
          tooltipText: "A számla kiállítása és lejárata közti idő"
          // "Amennyiben a vevődnek különböző lejáratra állítasz ki számlákat, akkor vedd alapul a leggyakoribb lejáratot."
        }
      ]
    ],
    [name]
  );

  const currencyRadios = useMemo(
    () => [
      {
        fieldType: "radio",
        name: `${name}.calculation.currency`,
        label: "HUF",
        value: "HUF"
      },
      {
        fieldType: "radio",
        name: `${name}.calculation.currency`,
        label: "EUR",
        value: "EUR"
      },
      {
        fieldType: "radio",
        name: `${name}.calculation.currency`,
        label: "USD",
        value: "USD"
      }
    ],
    [name]
  );

  const typeRadios = useMemo(
    () => [
      {
        fieldType: "radio",
        name: `${name}.calculation.natureOfTransaction`,
        label: "eseti",
        value: "CASE_BY_CASE",
        gridSize: 4
      },
      {
        fieldType: "radio",
        name: `${name}.calculation.natureOfTransaction`,
        label: "folyamatos",
        value: "CONTINUOUS",
        gridSize: 4
      }
    ],
    [name]
  );

  const localBusinessFields = useMemo(
    () => [
      [
        {
          fieldType: "radio",
          name: `${name}.localBusiness`,
          label: "Belföldi vevő",
          value: "true"
        },
        {
          fieldType: "radio",
          name: `${name}.localBusiness`,
          label: "Külföldi vevő",
          value: "false"
        }
      ]
    ],
    [name]
  );
  return (
    <Field
      name={`${name}.calculation.needInsurance`}
      subscription={{ initial: true }}
    >
      {({ input: { onChange: setNeedInsurance } }) => {
        return (
          <Field name={`${name}.localBusiness`} subscription={{ value: true }}>
            {({ input: { value: localBusiness } }) => {
              if (localBusiness === "false") {
                setNeedInsurance("true");
              }
              return (
                <Paper
                  style={{
                    padding: "32px",
                    margin: "24px 0 0",
                    minHeight: "610px"
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid xs={12} item>
                      <Typography variant="h5">Kalkuláció</Typography>
                      <Divider
                        style={{ marginTop: "1rem", marginBottom: "1rem" }}
                      />
                    </Grid>
                    <Grid item xs={12} id={"asd"}>
                      {!szamlazzHu && (
                        <Grid container alignItems={"center"}>
                          <Grid item xs={12}>
                            <Fields config={localBusinessFields} />
                          </Grid>
                        </Grid>
                      )}
                      {localBusiness === "false" && (
                        <Grid container>
                          <Grid item xs={12}>
                            <Fields
                              config={[
                                [
                                  {
                                    fieldType: "text",
                                    label: "Vevő neve",
                                    name: `${name}.party.partyName`,
                                    required: true,
                                    InputProps: {
                                      placeholder: "Kezdj el gépelni..."
                                    }
                                  }
                                ]
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Fields
                              config={[
                                [
                                  {
                                    fieldType: "text",
                                    label: "Vevő adószáma",
                                    name: `${name}.party.taxNumber`,
                                    required: true
                                  }
                                ]
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Fields
                              config={[
                                [
                                  {
                                    fieldType: "address",
                                    name: `${name}.party`,
                                    easyStructure: false,
                                    countriesUrl: `${getConfig(
                                      "API_URL"
                                    )}/countries`,
                                    showPostalBlock: false
                                  }
                                ]
                              ]}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {localBusiness === "true" && (
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            style={{
                              marginBottom: "1rem"
                            }}
                          >
                            {szamlazzHu ? (
                              <Fields
                                config={[
                                  [
                                    {
                                      fieldType: "bisnodeCompanySearch", // todo /flexipay-es urlt csinaltatni
                                      url: getFlexipayUrl(
                                        "/company/information/companies"
                                      ).replace("flexipay/", ""),
                                      easyStructure: true,
                                      name: `${name}.party`,
                                      label: "Vevő neve",
                                      required: true,
                                      icon: null,
                                      onChange: onCompanyChange,
                                      getOptions: () =>
                                        window.whitelistCustomers.map(
                                          ({ party }) => ({
                                            text: party.partyName,
                                            value: party
                                          })
                                        ),
                                      getInfo: value => {
                                        const item = window.whitelistCustomers.find(
                                          item =>
                                            item.party.taxNumber ===
                                            value.taxNumber
                                        );
                                        if (item.whitelist) {
                                          return (
                                            <Typography
                                              style={{
                                                color: "#FF6630",
                                                fontWeight: "800"
                                              }}
                                            >
                                              Minősített vevő, gyors
                                              bevizsgálás!
                                            </Typography>
                                          );
                                        }
                                      }
                                    }
                                  ]
                                ]}
                              />
                            ) : (
                              <Fields
                                config={[
                                  [
                                    {
                                      fieldType: "bisnodeCompanySearch", // todo /flexipay-es urlt csinaltatni
                                      url: getFlexipayUrl(
                                        "/company/information/companies"
                                      ).replace("flexipay/", ""),
                                      easyStructure: true,
                                      name: `${name}.party`,
                                      label: "Vevő neve",
                                      required: true,
                                      icon: null,
                                      onChange: onCompanyChange
                                    }
                                  ],
                                  [
                                    {
                                      fieldType: "text",
                                      label: "Vevő adószáma",
                                      name: `${name}.party.taxNumber`,
                                      disabled: true
                                    }
                                  ]
                                ]}
                              />
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        alignItems={"center"}
                        style={{
                          marginTop: isWidthDown("sm", width) ? "2rem" : "0"
                        }}
                      >
                        <Grid xs={12}>
                          <Fields config={invoicesAndTerms} />
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: "12px" }}>
                          <Typography variant={"h6"}>
                            Kiállított számlák pénzneme *
                            <Tooltip
                              title={`Ha egy vevődre több devizában is szeretnél ajánlatot kérni, készíts rájuk külön ajánlatot, az "új vevő" gomb használatával!`}
                            >
                              <img src={infoIcon} />
                            </Tooltip>
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginBottom: isWidthDown("sm", width)
                              ? "1rem"
                              : "0"
                          }}
                        >
                          <Fields config={[currencyRadios]} />
                        </Grid>
                      </Grid>
                      <Grid container alignItems={"center"} spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant={"h6"}>
                            Szeretnél biztosítást? *{" "}
                            <Tooltip
                              title={
                                "Célszerű biztosítást kérni, ha bizonytalan vagy benne, hogy a vevőd kifizeti-e a számlád. Biztosítási védelem esetén, ha a vevőd fizetésképtelenné válik, nem kérjük vissza a korábban folyósított számlaelőleget."
                              }
                            >
                              <img src={infoIcon} />
                            </Tooltip>
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginBottom: isWidthDown("sm", width)
                              ? "1rem"
                              : "0"
                          }}
                        >
                          <Fields
                            config={[
                              [
                                {
                                  fieldType: "radio",
                                  name: `${name}.calculation.needInsurance`,
                                  label: "Igen",
                                  value: "true",
                                  gridSize: 4
                                },
                                {
                                  fieldType: "radio",
                                  name: `${name}.calculation.needInsurance`,
                                  label: "Nem",
                                  value: "false",
                                  disabled: localBusiness === "false",
                                  gridSize: 4
                                }
                              ]
                            ]}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              );
            }}
          </Field>
        );
      }}
    </Field>
  );
};

const defaultValues = {
  companies: [
    {
      calculation: {
        natureOfTransaction: "CASE_BY_CASE",
        needInsurance: "true",
        currency: "HUF"
      },
      localBusiness: "true"
    }
  ]
};

const CalculatorForm = ({ onSubmit, width, initialValues = defaultValues }) => {
  const { szamlazzHu } = useProfileData();
  const [happDialogOpen, setHappDialogOpen] = useState(false);
  const [transSpedDialogOpen, setTransSpedDialogOpen] = useState(false);
  const { data: szamlazzhuCustomers = [] } = useSzamlazzhuCustomers(szamlazzHu);
  const messages = defineMessages({
    happDescription: {
      id: "happDescription",
      defaultMessage: `A <b>Happ Kft.</b> kiemelt együttműködő partnerünk – kérlek vedd fel a kapcsolatot ügyintézőnkkel, hogy egy nagyon kedvező ajánlatot tudjunk adni!<br><br><b>Telefonszám: +36 20 347 6882</b><br><b>Email: faktor@happ.hu</b>`
    },
    transSpedDescription: {
      id: "happDescription",
      defaultMessage: `A <b>Trans-sped Kft.</b> kiemelt együttműködő partnerünk – kérlek vedd fel a kapcsolatot ügyintézőnkkel, hogy egy nagyon kedvező ajánlatot tudjunk adni!<br><br><b>Telefonszám: +36 20 347 6882</b><br><b>Email: faktor@happ.hu</b>`
    }
  });
  console.log(initialValues);
  return (
    <Grid container>
      <Grid item xs={12}>
        <FlexiForm
          FinalFormFormProps={{
            onSubmit,
            initialValues
          }}
          decorators={[
            {
              field: /localBusiness/,
              updates: (line, name, allValues, prevValues) => {
                const needUpdates = Boolean(Object.keys(prevValues).length);

                if (needUpdates) {
                  return {
                    [name.replace(".localBusiness", "")]: {
                      localBusiness: line,
                      calculation: initialValues.companies[0].calculation
                    }
                  };
                } else {
                  return {};
                }
              }
            }
          ]}
          render={({ invalid, form, values }) => {
            // Ha fel van veve annyiszor ahany deviza letezik, akkor nem jelenik meg a listaban tobbet.
            const filteredWhiteLabelCustomers = szamlazzhuCustomers.filter(
              customer => {
                const items = values.companies.filter(
                  item => item.taxNumber === customer.taxNumber
                );
                // Ez azert 3 mert 3 deviza van (HUF, EUR, USD)
                return items.length <= 3;
              }
            );
            const containsHapp = values?.companies.find(
              item => item?.party?.taxNumber === "12497883-2-19"
            );
            const containsTransSped = values?.companies.find(
              item => item?.party?.taxNumber === "11622219-2-09"
            );
            return (
              <FieldArray name={"companies"}>
                {({ fields }) => {
                  return (
                    <Grid container spacing={1}>
                      {fields.map((name, i) => (
                        <Grid item xs={12} style={{ position: "relative" }}>
                          {(szamlazzHu || i > 0) && (
                            <Button
                              onClick={() => fields.remove(i)}
                              variant={"text"}
                              style={{
                                color: "red",
                                position: "absolute",
                                bottom: 40,
                                right: 10
                              }}
                            >
                              Vevő törlése
                            </Button>
                          )}
                          {/*{i === 0 && (*/}
                          {/*  <Divider*/}
                          {/*    style={{*/}
                          {/*      marginTop: "1rem",*/}
                          {/*      marginBottom: "1rem"*/}
                          {/*    }}*/}
                          {/*  />*/}
                          {/*)}*/}
                          <Line
                            onCompanyChange={value => {
                              if (value?.taxNumber === "12497883-2-19") {
                                setHappDialogOpen(true);
                              }
                              if (value?.taxNumber === "11622219-2-09") {
                                setTransSpedDialogOpen(true);
                              }
                            }}
                            name={name}
                            width={width}
                            szamlazzHu={szamlazzHu}
                            whitelistCustomers={filteredWhiteLabelCustomers}
                          />
                        </Grid>
                      ))}
                      <Grid
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                        style={{ marginTop: "12px", marginRight: "4px" }}
                      >
                        <Grid item>
                          <Button
                            variant={"text"}
                            onClick={() =>
                              fields.push({
                                localBusiness: "true",
                                calculation: {
                                  natureOfTransaction: "CASE_BY_CASE",
                                  needInsurance: "true",
                                  currency: "HUF"
                                }
                              })
                            }
                          >
                            +Új vevő hozzáadása
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            type={"submit"}
                            disabled={
                              invalid || containsHapp || containsTransSped
                            }
                          >
                            Kalkulálok!
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                }}
              </FieldArray>
            );
          }}
        />
      </Grid>
      <FlexiDialog
        dialogActions={
          <Button href={"mailto:faktor@happ.hu"} style={{ margin: "auto" }}>
            E-mailt küldök!
          </Button>
        }
        open={happDialogOpen}
        onClose={() => setHappDialogOpen(false)}
      >
        <Typography>
          <FormattedHTMLMessage {...messages.happDescription} />
        </Typography>
      </FlexiDialog>
      <FlexiDialog
        dialogActions={
          <Button href={"mailto:faktor@happ.hu"} style={{ margin: "auto" }}>
            E-mailt küldök!
          </Button>
        }
        open={transSpedDialogOpen}
        onClose={() => setTransSpedDialogOpen(false)}
      >
        <Typography>
          <FormattedHTMLMessage {...messages.transSpedDescription} />
        </Typography>
      </FlexiDialog>
    </Grid>
  );
};

export default withWidth()(CalculatorForm);
